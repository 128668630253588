.hero {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--text-color);
    background-color: var(--background-color);
    overflow: hidden;
    padding: 0 20px;
}

.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); /* Optional: Kann als Variable ergänzt werden */
    z-index: 1;
}

.hero-content {
    position: relative;
    z-index: 2;
    padding: 40px;
    background: var(--card-bg-color);
    border-radius: 10px;
    box-shadow: 0 10px 30px var(--box-shadow);
    max-width: 600px;
    margin: 0 auto;
    opacity: 0; /* Start transparent */
    transform: translateY(10px); /* Leichter Offset für einen sanften Effekt */
    animation: fadeIn 0.5s ease-out forwards; /* Schneller Fade-In */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.hero-title-part1, .hero-title-part2 {
    font-size: 3rem;
    color: var(--text-color);
    margin-bottom: 10px;
}

.hero-description {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: var(--paragraph-color);
}

.cta-button {
    background-color: var(--cta-button-bg);
    color: var(--cta-button-text-color);
    padding: 12px 24px;
    font-size: 1.2rem;
    border-radius: 50px;
    text-decoration: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-button:hover {
    transform: scale(1.1);
    background-color: var(--cta-button-hover-bg);
}

.hero-image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
}

.hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(3px);
}

/* Anpassungen für kleinere Bildschirme */
@media (max-width: 768px) {
    .hero {
        height: 70vh;
        padding: 0 10px;
    }

    .hero-content {
        padding: 20px;
        max-width: 90%;
    }

    .hero-title-part1, .hero-title-part2 {
        font-size: 2rem;
    }

    .hero-description {
        font-size: 1.2rem;
    }

    .cta-button {
        padding: 10px 20px;
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .hero {
        height: 60vh;
    }

    .hero-title-part1, .hero-title-part2 {
        font-size: 1.7rem;
    }

    .hero-description {
        font-size: 1rem;
    }

    .cta-button {
        padding: 8px 16px;
        font-size: 0.9rem;
    }
}
