/* Impressum-spezifische Stile */

/* Container */
.impressum-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .impressum-row {
    display: flex;
    justify-content: center;
  }
  
  .impressum-col {
    flex: 1;
  }
  
  /* Box-Design */
  .impressum-border {
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    background-color: #ffffff;
  }
  
  /* Überschriften und Text */
  .impressum-title {
    font-size: 28px;
    color: #343a40;
  }
  
  .impressum-heading {
    font-size: 22px;
    font-weight: bold;
    color: #495057;
  }
  
  .impressum-section p,
  .impressum-section a {
    color: #212529;
  }
  
  .impressum-subheading {
    font-size: 20px;
    color: #6c757d;
  }
  
  /* Link-Stil */
  .impressum-section a {
    text-decoration: none;
    color: #007bff;
  }
  
  .impressum-section a:hover {
    text-decoration: underline;
  }
  
  /* Mobile Anpassungen */
  @media screen and (max-width: 768px) {
    .impressum-container {
      padding: 10px;
    }
  
    .impressum-title {
      font-size: 24px;
    }
  
    .impressum-heading {
      font-size: 20px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .impressum-title {
      font-size: 20px;
    }
  
    .impressum-heading {
      font-size: 18px;
    }
  
    .impressum-section p,
    .impressum-section a {
      font-size: 14px;
    }
  }
  