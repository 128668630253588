

.faqs {
  max-width: 1200px;
  width: 100%;
  padding: 40px;
  background-color: var(--card-bg-color); /* Variable für den FAQ-Hintergrund */
  border-radius: 10px;
  box-shadow: 0 4px 8px var(--box-shadow); /* Variable für den Schatten */
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.faq-item {
  background-color: var(--card-bg-color); /* Variable für den Karten-Hintergrund */
  border-radius: 8px;
  box-shadow: 0 2px 4px var(--box-shadow); /* Variable für den Schatten */
  padding: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.faq-item.active {
  background-color: var(--card-hover-bg-color); /* Variable für den aktiven Karten-Hintergrund */
  box-shadow: 0 4px 8px var(--hover-box-shadow); /* Variable für den Hover-Schatten */
}

.faq-question {
  font-size: 1.25em;
  color: var(--text-color); /* Variable für die Textfarbe */
}

.faq-answer {
  color: var(--paragraph-color); /* Variable für den Text */
  font-family: Arial, sans-serif;
  margin-top: 10px;
}
