

/* Service-Bereich */
.services {
  max-width: 1200px;
  width: 100%;
  padding: 40px;
  background-color: var(--card-bg-color); /* Karten-Hintergrund */
  border-radius: 10px;
  box-shadow: 0 4px 8px var(--box-shadow); /* Schatteneffekt */
  text-align: center;
}

/* Überschrift im Service-Bereich */
.services h2 {
  margin-bottom: 30px;
  color: var(--text-color);
  font-size: 2rem; /* Größere Überschrift */
}

/* Flexbox-Container für Service-Blöcke */
.service-blocks {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

/* Service-Block */
.service-block {
  background-color: var(--card-bg-color); /* Block-Hintergrund */
  border-radius: 8px;
  box-shadow: 0 4px 8px var(--box-shadow); /* Schatten */
  padding: 20px;
  max-width: 300px; /* Maximale Breite */
  flex: 1 1 300px; /* Flex-Layout für gleichmäßige Verteilung */
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
/* Hover-Effekt */
.service-block:hover {
  transform: translateY(-5px); /* Leichter Aufwärtseffekt */
  box-shadow: 0 6px 12px var(--box-shadow-hover); /* Stärkerer Schatten */
}

/* Service-Icon */
.service-icon {
  font-size: 50px;
  color: var(--header-border-color); /* Farb-Akzent */
  margin-bottom: 10px;
  
}

/* Service-Überschrift */
.service-block h3 {
  margin: 10px 0;
  color: var(--text-color); /* Textfarbe */
  font-size: 1.5rem; /* Kleinere Schriftgröße */
}

/* Service-Text */
.service-block p {
  color: var(--paragraph-color);
  font-family: Arial, sans-serif;
  font-size: 1rem;
}

/* Responsive Anpassungen für Tablets */
@media (max-width: 768px) {
  .services {
    padding: 30px; /* Weniger Padding */
  }

  .services h2 {
    font-size: 1.8rem; /* Kleinere Überschrift */
  }

  .service-blocks {
    gap: 15px; /* Weniger Abstand zwischen den Blöcken */
  }

  .service-block {
    max-width: 45%; /* Zwei Blöcke nebeneinander auf Tablets */
    flex: 1 1 45%; /* Gleichmäßige Verteilung */
    padding: 15px; /* Weniger Padding */
  }

  .service-block h3 {
    font-size: 1.4rem; /* Kleinere Schriftgröße */
  }

  .service-block p {
    font-size: 0.95rem; /* Kleinere Schriftgröße */
  }
}

/* Responsive Anpassungen für Smartphones */
@media (max-width: 480px) {
  .services {
    padding: 20px; /* Weniger Padding */
   
  }

  .services h2 {
    font-size: 1.6rem; /* Kleinere Überschrift */
  }

  .service-blocks {
    flex-direction: column; /* Vertikale Anordnung */
    gap: 10px; /* Weniger Abstand */
  }

  .service-block {
   margin-left: 7.50%;
    max-width: 85%; /* Volle Breite auf Smartphones */
    flex: 1 1 100%; /* Volle Breite */
    padding: 15px; /* Gleichmäßiges Padding */
  }

  .service-block h3 {
    font-size: 1.3rem; /* Kleinere Schriftgröße */
  }

  .service-block p {
    font-size: 0.9rem; /* Kleinere Schriftgröße */
  }

  .service-icon {
    font-size: 40px; /* Kleinere Icons für mobile Geräte */
  }
}
