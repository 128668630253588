/* Parallax Scrolling Article Styles */

:root {
    --parallax-light-bg: linear-gradient(135deg, #ffffff 0%, #f3f4f6 100%);
    --parallax-dark-bg: linear-gradient(135deg, #2b2b2b 0%, #1e1e1e 100%);
    --parallax-light-text: #333;
    --parallax-dark-text: #f5f5f5;
    --parallax-meta-light-text: #666;
    --parallax-meta-dark-text: #bbbbbb;
    --parallax-section-light-bg: #fff;
    --parallax-section-dark-bg: #3a3a3a;
    --parallax-border-light: #ff6f61;
    --parallax-border-dark: #bb86fc;
    --parallax-footer-light-border: #ddd;
    --parallax-footer-dark-border: #555;
    --parallax-footer-light-text: #ff6f61;
    --parallax-footer-dark-text: #bb86fc;
    --parallax-box-shadow-light: rgba(0, 0, 0, 0.1);
    --parallax-box-shadow-dark: rgba(0, 0, 0, 0.5);
}

body.dark-mode .parallax-article-container {
    background: var(--parallax-dark-bg);
    box-shadow: 0 10px 20px var(--parallax-box-shadow-dark);
}

body.dark-mode .parallax-article-header h1 {
    color: var(--parallax-dark-text);
    text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.1);
}

body.dark-mode .parallax-article-meta {
    color: var(--parallax-meta-dark-text);
}

body.dark-mode .parallax-article-content {
    color: var(--parallax-dark-text);
}

body.dark-mode .parallax-article-section {
    background-color: var(--parallax-section-dark-bg);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

body.dark-mode .parallax-article-section h2 {
    color: var(--parallax-dark-text);
    border-bottom: 3px solid var(--parallax-border-dark);
}

body.dark-mode .parallax-article-footer {
    border-top: 1px solid var(--parallax-footer-dark-border);
}

body.dark-mode .parallax-article-footer p {
    color: var(--parallax-footer-dark-text);
}

/* Light Mode Styles */
.parallax-article-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px;
    background: var(--parallax-light-bg);
    border-radius: 15px;
    box-shadow: 0 10px 20px var(--parallax-box-shadow-light);
}

.parallax-article-header {
    text-align: center;
    margin-bottom: 40px;
}

.parallax-article-header h1 {
    font-size: 3.5rem;
    color: var(--parallax-light-text);
    margin-bottom: 15px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.parallax-article-meta {
    font-size: 1rem;
    color: var(--parallax-meta-light-text);
    font-style: italic;
}

.parallax-article-content {
    line-height: 1.8;
    color: var(--parallax-light-text);
    font-size: 1.1rem;
}

.parallax-article-section {
    margin-bottom: 50px;
    padding: 20px;
    background-color: var(--parallax-section-light-bg);
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.parallax-article-section h2 {
    font-size: 2.2rem;
    color: var(--parallax-light-text);
    margin-bottom: 20px;
    border-bottom: 3px solid var(--parallax-border-light);
    display: inline-block;
    padding-bottom: 5px;
}

.parallax-article-section p {
    margin-bottom: 20px;
}

.parallax-article-footer {
    text-align: center;
    margin-top: 60px;
    padding-top: 20px;
    border-top: 1px solid var(--parallax-footer-light-border);
}

.parallax-article-footer p {
    font-size: 1.3rem;
    color: var(--parallax-footer-light-text);
}

/* Responsive Design */
@media (max-width: 768px) {
    .parallax-article-container {
        padding: 20px;
    }

    .parallax-article-header h1 {
        font-size: 2.8rem;
    }

    .parallax-article-section h2 {
        font-size: 2rem;
    }

    .parallax-article-section p {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .parallax-article-header h1 {
        font-size: 2.5rem;
    }

    .parallax-article-section h2 {
        font-size: 1.8rem;
    }

    .parallax-article-section p {
        font-size: 0.9rem;
    }
}