/* Hauptcontainer */
.cta-container {
  background-color: var(--container-bg-color);
  color: var(--text-color);
  padding: 40px 20px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 10px var(--box-shadow);
}

/* Inhalt */
.cta-content h2 {
  font-size: 2rem;
  margin-bottom: 15px;
  color: var(--header-border-color);
}

.cta-content p {
  font-size: 1rem;
  margin-bottom: 25px;
  color: var(--paragraph-color);
}

/* Links */
.cta-links {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.cta-row {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap; /* Zeilenumbruch ermöglichen */
}

.cta-link {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: var(--text-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.cta-link:hover {
  color: var(--cta-button-hover-bg);
}

.cta-icon {
  margin-right: 10px;
  font-size: 1.2rem;
  color: var(--header-border-color);
}

/* Footer */
.cta-footer {
  margin-top: 20px;
  font-size: 0.9rem;
}

.footer-link {
  color: var(--text-color);
  text-decoration: none;
}

.footer-link:hover {
  color: var(--cta-button-hover-bg);
}

/* Mobile Anpassungen */
@media (max-width: 768px) {
  .cta-row {
    flex-direction: column; /* Links werden gestapelt */
    gap: 10px;
  }

  .cta-link {
    justify-content: center; /* Zentrierte Links */
    font-size: 0.9rem; /* Kleinere Schriftgröße für mobile Geräte */
  }
}
