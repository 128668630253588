
/* SEO-Sektion */
.services {
  max-width: 1200px;
  width: 100%;
  padding: 40px;
  background-color: var(--card-bg-color);
  border-radius: 10px;
  box-shadow: 0 4px 12px var(--box-shadow);
}

/* Allgemeine Stile für die SEO-Sektionen */
.seo-section {
  margin-bottom: 40px;
  padding: 30px;
  background-color: var(--card-bg-color);
  border-radius: 10px;
  box-shadow: 0 4px 8px var(--box-shadow);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.seo-section:hover {
  transform: translateY(-5px);
  background-color: var(--card-hover-bg-color);
  box-shadow: 0 6px 16px var(--hover-box-shadow);
}

.seo-heading {
  font-size: 1.8em;
  font-weight: 700;
  color: var(--text-color);
  margin-bottom: 15px;
}

.seo-text {
  font-size: 1em;
  color: var(--paragraph-color);
  line-height: 1.6;
}

/* Liste */
.seo-list {
  margin-top: 15px;
  padding-left: 20px;
  list-style-type: disc;
}

.seo-list li {
  font-size: 1em;
  color: var(--paragraph-color);
  margin-bottom: 10px;
}

/* Diagramm-Stil */
.seo-chart-section {
  padding: 30px;
  background-color: var(--card-bg-color);
  border-radius: 10px;
  box-shadow: 0 4px 8px var(--box-shadow);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.seo-chart {
  margin-top: 20px;
  width: 100%;
}

/* Call-to-Action */
.seo-cta-section {
  text-align: center;
  background-color: var(--cta-bg-color);
  color: var(--cta-text-color);
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 12px var(--hover-box-shadow);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.seo-cta-button {
  margin-top: 20px;
  background-color: var(--cta-button-bg);
  color: var(--cta-button-text-color);
  border: none;
  padding: 15px 30px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.seo-cta-button:hover {
  background-color: var(--cta-button-hover-bg);
}

/* Animation */
[data-aos="fade-up"] {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

[data-aos="fade-up"].aos-animate {
  opacity: 1;
  transform: translateY(0);
}

/* Mobile Anpassungen für das Diagramm */
@media (max-width: 768px) {
  .seo-chart-section {
      padding: 20px;
  }

  .seo-chart {
      max-width: 100%; /* Diagramm auf Bildschirmbreite skalieren */
      height: auto; /* Höhe automatisch anpassen */
  }
}

@media (max-width: 480px) {
  .container {
      padding: 10px;
  }

  .services {
      padding: 20px;
  }

  .seo-section {
      padding: 20px;
      margin-bottom: 20px;
  }

  .seo-heading {
      font-size: 1.5em;
  }

  .seo-text {
      font-size: 0.9em;
  }

  .seo-chart-section {
      padding: 15px;
  }

  .seo-chart {
      max-width: 100%; /* Diagramm nimmt volle Breite ein */
      height: auto;

    
  }

  .seo-cta-button {
      padding: 10px 20px;
      font-size: 1em;
  }
}
