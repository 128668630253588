

/* Preisbereich */
.pricing {
  max-width: 1200px;
  padding: 40px;
  background-color: var(--card-bg-color);
  border-radius: 10px;
  box-shadow: 0 4px 8px var(--box-shadow);
  text-align: center;
  margin: 0 auto;
}

/* Grid Layout für die Preisboxen */
.pricing-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); /* Dynamische Spalten */
  gap: 30px; /* Abstand zwischen den Boxen */
  margin-top: 40px;
}

/* Preisboxen */
.pricing-section {
  padding: 20px;
  background-color: var(--card-hover-bg-color);
  border-radius: 8px;
  box-shadow: 0 2px 4px var(--box-shadow);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

/* Hover-Effekt für die Boxen */
.pricing-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px var(--hover-box-shadow);
}

/* Überschrift in der Preisbox */
.pricing-section h3 {
  font-size: 1.8rem;
  color: var(--text-color);
  margin-bottom: 15px;
}

/* Text in der Preisbox */
.pricing-header p {
  color: var(--paragraph-color);
  font-family: Arial, sans-serif;
  font-size: 1.1rem;
  margin-bottom: 20px;
}

/* CTA-Bereich */
.cta-section {
  margin-top: 50px;
  padding: 20px;
  background-color: var(--cta-section-bg-color);
  color: var(--cta-section-text-color);
  border-radius: 8px;
  text-align: center;
}

.cta-section p {
  margin: 0 0 20px;
  font-family: Arial, sans-serif;
  font-size: 1.2rem;
}

/* CTA-Button */
.cta-button {
  display: inline-block;
  padding: 12px 24px;
  background-color: var(--cta-button-bg);
  color: var(--cta-button-text-color);
  text-decoration: none;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.cta-button:hover {
  background-color: var(--cta-button-hover-bg);
  transform: translateY(-3px);
}

/* Responsive Anpassungen für Tablets */
@media (max-width: 768px) {
  .pricing {
    padding: 30px; /* Weniger Padding für Tablets */
  }

  .pricing-info {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Dynamische Spalten für Tablets */
    gap: 20px;
  }

  .pricing-section h3 {
    font-size: 1.6rem; /* Kleinere Schriftgröße */
  }
}

/* Responsive Anpassungen für Smartphones */
@media (max-width: 480px) {
  .pricing {
    padding: 20px;
  }

  .pricing-info {
    grid-template-columns: 1fr; /* Eine Spalte auf Smartphones */
    gap: 15px;
  }

  .pricing-section h3 {
    font-size: 1.4rem; /* Kleinere Überschrift */
  }

  .pricing-header p,
  .cta-section p {
    font-size: 1rem; /* Kleinere Schriftgröße */
  }

  .cta-button {
    padding: 10px 20px; /* Weniger Padding */
    font-size: 0.9rem; /* Kleinere Schriftgröße */
  }
}
