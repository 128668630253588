/* WebDesign.css */

.container {
  padding: 60px 0;
  overflow: hidden;
}

.web-design-section {
  margin-bottom: 80px;
  padding: 40px 20px;
}

/* Mobile Anpassungen für Container und Cards */
@media (max-width: 768px) {
  .container {
    padding: 20px 0;
  }

  .web-design-section {
    padding: 20px 10px;
    margin-bottom: 40px; /* Reduzierter Abstand zwischen den Abschnitten */
  }

  .card {
    padding: 20px;
   
  }
  .web-design-cta-button {
    max-height: 5% !important;
    
  }
}

@media (max-width: 480px) {
  .container {
    padding: 10px 0;
  }

  .web-design-section {
    padding: 15px 5px;
    margin-bottom: 30px;
  }

  .card {
    padding: 15px;
   margin: 0px 0px !important;

  }

  .services
  {
    padding: 10px !important;
  }
}

/* Restlicher Style... */
.carousel-slider .slick-slide {
  display: flex;
  justify-content: center;
}


.card {
  background: linear-gradient(135deg, var(--card-bg-color), var(--card-hover-bg-color));
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 8px var(--box-shadow);
  text-align: center;
  color: var(--text-color);
  margin: 0 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.equal-height-card {
  min-height: 350px; /* Anpassung der Mindesthöhe für Bilder */
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 24px var(--hover-box-shadow);
}

.intro-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
}

.card-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 15px;
}

.web-design-heading {
  font-size: 2.2em;
  margin-bottom: 25px;
  text-align: center;
}

.web-design-text {
  color: var(--paragraph-color);
  font-size: 1.2em;
  line-height: 1.8;
}

.service-icon, .process-icon {
  font-size: 3em;
  color: var(--cta-button-bg);
  margin-bottom: 20px;
}

.web-design-cta-button {
  background-color: var(--cta-button-bg);
  color: var(--cta-button-text-color);
  border: none;
  padding: 20px 40px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.4em;
  font-weight: 600;
  margin-top: 40px;
}
