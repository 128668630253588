@import url(../../assets/variables.css);

.portfolio-container {
  padding: 40px;
  background-color: var(--card-bg-color); /* Variable für den Hintergrund */
  border-radius: 10px;
  box-shadow: 0 4px 8px var(--box-shadow); /* Variable für den Schatten */
 max-width: 1200px;
  margin: 0 auto;
  max-width: 100vw;
}

h1 {
  font-size: 2.5rem;
  color: var(--text-color); /* Variable für die Textfarbe */
  text-align: center;
  margin-bottom: 40px;
}

.portfolio-section {
  margin-bottom: 60px;
}

.portfolio-section h2 {
  font-size: 2rem;
  color: var(--text-color); /* Variable für die Textfarbe */
  margin-bottom: 20px;
  text-align: center;
}

.portfolio-projects {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap; /* Ermöglicht Umbrüche auf kleineren Bildschirmen */
}

.portfolio-image {
  width: calc(33.333% - 20px); /* Drei Bilder nebeneinander, mit Abstand */
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 8px var(--box-shadow); /* Variable für den Schatten */
  transition: transform 0.3s ease;
}

.portfolio-image:hover {
  transform: scale(1.05);
}

.portfolio-approach {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.approach-card {
  background-color: var(--card-bg-color); /* Variable für den Karten-Hintergrund */
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 12px var(--box-shadow); /* Variable für den Schatten */
  width: calc(33.333% - 20px); /* Drei Cards nebeneinander, mit Abstand */
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.approach-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px var(--hover-box-shadow); /* Variable für den Hover-Schatten */
}

.approach-icon {
  font-size: 3rem;
  color: var(--header-border-color); /* Variable für die Icon-Farbe */
  margin-bottom: 15px;
}

.approach-card h3 {
  font-size: 1.5rem;
  color: var(--text-color); /* Variable für die Textfarbe */
  margin-bottom: 15px;
}

.approach-card p {
  font-size: 1rem;
  color: var(--paragraph-color); /* Variable für den Text */
}

/* Responsive Design */
@media (max-width: 768px) {
  .portfolio-image {
      width: calc(50% - 20px); /* Zwei Bilder nebeneinander auf kleinen Bildschirmen */
  }

  .approach-card {
      width: calc(50% - 20px); /* Zwei Cards nebeneinander auf kleinen Bildschirmen */
  }
}

@media (max-width: 480px) {
  .portfolio-image {
      width: 100%; /* Ein Bild pro Zeile auf sehr kleinen Bildschirmen */
  }

  .approach-card {
      width: 100%; /* Eine Card pro Zeile auf sehr kleinen Bildschirmen */
  }
}
