@font-face {
    font-family: 'Source Code Pro';
    src: url('SourceCodeRoman-ExtraLight.woff2') format('woff2'),
        url('SourceCodeRoman-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: url('SourceCodeItalic-ExtraLightItalic.woff2') format('woff2'),
        url('SourceCodeItalic-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-Thin.woff2') format('woff2'),
        url('Raleway-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-ThinItalic.woff2') format('woff2'),
        url('Raleway-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Regular.woff2') format('woff2'),
        url('OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Italic.woff2') format('woff2'),
        url('OpenSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-ThinItalic.woff2') format('woff2'),
        url('Montserrat-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Thin.woff2') format('woff2'),
        url('Montserrat-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Black.woff2') format('woff2'),
        url('Lato-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-BlackItalic.woff2') format('woff2'),
        url('Lato-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato Hairline';
    src: url('Lato-Hairline.woff2') format('woff2'),
        url('Lato-Hairline.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Light.woff2') format('woff2'),
        url('Lato-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato Hairline';
    src: url('Lato-HairlineItalic.woff2') format('woff2'),
        url('Lato-HairlineItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Italic.woff2') format('woff2'),
        url('Lato-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-BoldItalic.woff2') format('woff2'),
        url('Lato-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-LightItalic.woff2') format('woff2'),
        url('Lato-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Regular.woff2') format('woff2'),
        url('Lato-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Bold.woff2') format('woff2'),
        url('Lato-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('BebasNeue-Regular.woff2') format('woff2'),
        url('BebasNeue-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Russo One';
    src: url('RussoOne-Regular.woff2') format('woff2'),
        url('RussoOne-Regular.woff') format('woff');
    font-weight: 100 900;
    font-style: normal;
    font-display: swap;
}

