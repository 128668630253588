.container {
    padding: 60px 0;
    overflow: hidden;
}

.webapp-section {
    margin-bottom: 60px;
    padding: 40px 20px;
}

.services {
    max-width: 1200px;
    width: 100%;
    padding: 40px;
    background-color: var(--card-bg-color);
    border-radius: 10px;
    box-shadow: 0 4px 12px var(--box-shadow);
}

.webapp-heading {
    font-size: 2em;
    color: var(--header-border-color);
    margin-bottom: 25px;
    text-align: center;
}

.webapp-text {
    color: var(--paragraph-color);
    font-size: 1.2em;
    line-height: 1.8;
    text-align: center;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.card {
    background-color: var(--card-bg-color);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px var(--box-shadow);
    text-align: center;
    color: var(--text-color);
    margin: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.small-card {
    width: 280px;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 16px var(--hover-box-shadow);
}

.service-icon {
    font-size: 2.5em;
    color: var(--cta-button-bg);
    margin-bottom: 15px;
}

/* Vorteile Abschnitt */
.advantages-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 20px;
    padding: 20px;
}

.advantage-card {
    background-color: var(--card-bg-color);
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px var(--box-shadow);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.advantage-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 16px var(--hover-box-shadow);
}

.advantage-icon {
    font-size: 2.5em;
    color: var(--cta-button-bg);
    margin-bottom: 10px;
}

.advantage-card h5 {
    font-size: 1.3em;
    color: var(--text-color);
    margin-bottom: 10px;
}

.advantage-card p {
    font-size: 1em;
    color: var(--paragraph-color);
    line-height: 1.5;
}

/* Call-to-Action */
.cta-button {
    background-color: var(--cta-button-bg);
    color: var(--cta-button-text-color);
    border: none;
    padding: 15px 30px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2em;
    font-weight: 600;
    transition: background-color 0.3s ease;
}

.cta-button:hover {
    background-color: var(--cta-button-hover-bg);
}

/* Mobile Anpassungen */
@media (max-width: 768px) {
    .container {
        padding: 40px 0;
    }

    .webapp-heading {
        font-size: 1.8em;
    }

    .webapp-text {
        font-size: 1em;
    }
}

@media (max-width: 480px) {
    .container {
        padding: 30px 0;
    }

    .webapp-heading {
        font-size: 1.6em;
    }

    .webapp-text {
        font-size: 0.9em;
    }

    .service-icon, .advantage-icon {
        font-size: 2em;
    }

    .advantage-card h5 {
        font-size: 1.2em;
    }
}
