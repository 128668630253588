@import url(../src/fonts/stylesheet.css);
html{
  overflow-x: hidden;
}
/* Schriftart für alle Überschriften und Absätze */
h1, h2, h3, h4, h5, h6, p, a {
    font-family: "Russo One", sans-serif;
    font-weight: 400;
    font-style: normal;
    white-space: normal; /* Sicherstellen, dass Text nicht bricht */
}

/* Container-Anpassung für Bootstrap */
.container {
    max-width: 1140px; /* Maximalbreite für Container */
    padding: 15px; /* Konsistentes Padding */
    margin: 0 auto; /* Zentrierung */
}

/* Schriftgrößen und Abstände für verschiedene Überschriften */
h1 {
    font-size: 3.5rem;
    margin-bottom: 20px;
}

h2 {
    font-size: 2.8rem;
    margin-bottom: 18px;
}

h3 {
    font-size: 2.2rem;
    margin-bottom: 16px;
}

h4 {
    font-size: 1.8rem;
    margin-bottom: 14px;
}

h5 {
    font-size: 1.4rem;
    margin-bottom: 12px;
}

h6 {
    font-size: 1.1rem;
    margin-bottom: 10px;
}

/* Standard-Absatzgröße und Zeilenhöhe */
p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 20px;
}

/* Link-Styling */
a {
    text-decoration: none  !important; /* Standardmäßig keine Unterstreichung */
    color: #007bff; /* Bootstrap-Blau */
}

a:hover {
    text-decoration: underline; /* Unterstreichung bei Hover */
}

/* Responsive Anpassungen */
@media (max-width: 768px) {
  h1 {
    font-size: 2.8rem; /* Kleinere Schriftgrößen auf Tablets */
    margin-bottom: 18px;
  }

  h2 {
    font-size: 2.2rem;
    margin-bottom: 16px;
  }

  h3 {
    font-size: 1.8rem;
    margin-bottom: 14px;
  }

  h4 {
    font-size: 1.5rem;
    margin-bottom: 12px;
  }

  h5 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  h6 {
    font-size: 1rem;
    margin-bottom: 8px;
  }

  p {
    font-size: 0.9rem; /* Kleinere Schriftgröße für Text */
    margin-bottom: 15px; /* Weniger Abstand */
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 2.5rem; /* Weitere Reduzierung der Überschriftengröße */
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.6rem;
  }

  h4 {
    font-size: 1.3rem;
  }

  h5 {
    font-size: 1.1rem;
  }

  h6 {
    font-size: 0.9rem;
  }

  p {
    font-size: 0.85rem; /* Anpassung für kleinere Geräte */
    margin-bottom: 10px;
  }

  a {
    font-size: 0.85rem; /* Kleinere Links auf kleinen Geräten */
  }
}

/* Box-Styling für einheitliche Abschnitte */
.box {
    padding: 20px;
    border: 1px solid var(--input-border-color); /* Rahmenfarbe */
    border-radius: 10px;
    background-color: var(--card-bg-color); /* Hintergrundfarbe */
    box-shadow: 0 4px 8px var(--box-shadow); /* Schatteneffekt */
    margin-bottom: 20px;
}

.box:hover {
    box-shadow: 0 6px 12px var(--hover-box-shadow); /* Stärkerer Schatten bei Hover */
    transform: translateY(-5px); /* Leichte Aufwärtsbewegung */
}

/* Weitere Anpassungen für Bootstrap */
.navbar-brand {
    font-size: 1.8rem; /* Größere Logo-Größe */
}

.navbar-nav .nav-item .nav-link {
    font-size: 1.1rem; /* Standardgröße für Links */
    padding: 10px 15px; /* Mehr Abstand für bessere Klickfläche */
}

.footer {
    background-color: var(--footer-bg-color); /* Footer-Hintergrund */
    color: var(--footer-text-color); /* Footer-Textfarbe */
    padding: 30px 15px; /* Innenabstände */
    text-align: center; /* Zentrierter Text */
}

.footer p {
    margin: 0;
    font-size: 0.9rem; /* Kleinere Textgröße */
}

.container {
  background-color: var(--container-bg-color);
}