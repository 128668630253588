/* Light Mode (default) */
:root {
    --background-color: #ffffff;
    --text-color: #333333;
    --card-bg-color: #ffffff;
    --container-bg-color: #f9f9f9;
    --card-hover-bg-color: #f9f9f9;
    --header-border-color: #ff6f61;
    --paragraph-color: #666666;
    --box-shadow: rgba(0, 0, 0, 0.1);
    --hover-box-shadow: rgba(0, 0, 0, 0.2);
    --cta-button-bg: #ff6f61;
    --cta-button-text-color: #121212;
    --cta-button-hover-bg: #f55546;
    --author-text: black;
    --toggler-bg-color: transparent;
  }
  
  /* Dark Mode */
  body.dark-mode {
    --navbar-text-color: #ffffff;
    --background-color: #121212;
    --text-color: #f5f5f5;
    --card-bg-color: #1c1c1c;
    --container-bg-color: #2b2b2b;
    --card-hover-bg-color: #333333;
    --header-border-color: #9b59b6;
    --paragraph-color: #bdbdbd;
    --box-shadow: rgba(0, 0, 0, 0.5);
    --hover-box-shadow: rgba(0, 0, 0, 0.7);
    --cta-button-bg: #9b59b6;
    --cta-button-text-color: #ffffff;
    --cta-button-hover-bg: #9b59b6;
    --author-text: rgb(207, 207, 207);
    --navbar-bg-color: rgba(41, 40, 40, 0.7); 
    --toggler-bg-color: #474747a6 ;

  }
  body.dark-mode {
    --icon-check-color-dark: #50fa7b; /* Ein helles Grün für Häkchen im Darkmode */
    --icon-times-color-dark: #ff5555; /* Ein warmes Rot für Kreuze im Darkmode */
  }
  
  :root {
    --icon-check-color-light: #28a745; /* Ein freundliches Grün für Häkchen im Lightmode */
    --icon-times-color-light: #dc3545; /* Ein kräftiges Rot für Kreuze im Lightmode */
  }
  