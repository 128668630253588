/* Testimonials Sektion */
.testimonials-section {
  padding: 60px 20px;
  background-color: var(--card-bg-color);
  text-align: center;
}
.testimonials
{
  max-width: 1200px;
  width: 100%;
  padding: 40px;
  background-color: var(--card-bg-color); /* Karten-Hintergrund */
  border-radius: 10px;
  box-shadow: 0 4px 8px var(--box-shadow); /* Schatteneffekt */
  text-align: center;
}
.testimonials-heading {
  font-size: 2.5rem;
  color: var(--testimonial-text);
  margin-bottom: 40px;
}

/* Flexibles Carousel */
.testimonials-carousel {
  width: 100%;
  max-width: 1200px; /* Maximalbreite für große Bildschirme */
  margin: 0 auto;
  padding: 0 15px;
  overflow: hidden;
}

/* Testimonial-Karten */
.testimonials-carousel .testimonial-card {
  background-color: var(--testimonial-bg);
  box-shadow: 0 4px 8px var(--box-shadow);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  min-height: 250px;
  max-width: 320px; /* Begrenzung der Breite der Karten */
}

.testimonials-carousel .testimonial-text {
  font-size: 1rem;
  font-weight: lighter;
  line-height: 1.6;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 15px;
}

.testimonials-carousel .testimonial-image {
  border-radius: 50%;
  margin-bottom: 30px;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.testimonials-carousel .testimonial-author {
  margin-top: 10px;
  font-size: 1rem;
  color: var(--author-text);
  text-align: center;
}

/* Slick Slider Anpassungen */
.slick-slide {
  padding: 10px;
}

.slick-dots {
  bottom: -30px;
}

.slick-prev:before,
.slick-next:before {
  color: var(--testimonial-text);
}

/* Responsives Design für Tablets */
@media (max-width: 768px) {
  .testimonials-carousel .testimonial-card {
    padding: 15px;
    margin: 10px;
    min-height: auto;
    max-width: 90%; /* Breite für Tablets */
  }

  .testimonials-carousel .testimonial-text {
    font-size: 0.9rem;
    margin-top: 10px;
  }

  .testimonials-carousel .testimonial-author {
    font-size: 0.9rem;
  }

  .testimonials-carousel .testimonial-image {
    width: 80px;
    height: 80px;
  }
}

/* Responsives Design für Smartphones */
@media (max-width: 480px) {
  .testimonials-heading {
    font-size: 2rem;
  }

  .testimonials-carousel {
    padding: 0 10px;
  }

  .testimonials-carousel .testimonial-card {
    padding: 10px;
    margin: 5px;
    min-height: 200px;
    max-width: 100%; /* Karten füllen den verfügbaren Platz auf mobilen Geräten */
  }

  .testimonials-carousel .testimonial-text {
    font-size: 0.8rem;
    margin-top: 8px;
  }

  .testimonials-carousel .testimonial-author {
    font-size: 0.8rem;
  }

  .testimonials-carousel .testimonial-image {
    width: 70px;
    height: 70px;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 1.2rem;
  }
}
