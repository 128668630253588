/* Hauptcontainer für die Tabelle */





/* Standard-Styling */
.icon-check {
    color: var(--icon-check-color-light); /* Standard für Lightmode */
}

body.dark-mode .icon-check {
    color: var(--icon-check-color-dark); /* Häkchen im Darkmode */
}

.icon-times {
    color: var(--icon-times-color-light); /* Standard für Lightmode */
}

body.dark-mode .icon-times {
    color: var(--icon-times-color-dark); /* Kreuze im Darkmode */
}



.comparison-table-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: auto;
}

.comparison-table {
    width: 100%;
    max-width: 1000px;
    border-collapse: separate;
    border-spacing: 0 10px;
    background-color: var(--card-bg-color);
    box-shadow: 0 4px 15px var(--box-shadow);
    border-radius: 10px;
    overflow: hidden;
}

/* Kopfzeile der Tabelle */
.comparison-table thead {
    background-color: var(--cta-button-bg-color);
    color: var(--navbar-text-color);
}

.comparison-table th {
    padding: 1rem;
    font-family: 'Russo One', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1.1rem;
    text-align: left;
}

/* Tabellenzellen */
.comparison-table td {
    padding: 1.5rem;
    text-align: center;
    font-size: 1.5rem;
    border: none;
    background-color: var(--container-bg-color);
    color: var(--text-color);
}

/* Hover-Effekt */
.comparison-table tr:hover td {
    background-color: var(--card-hover-bg-color);
    transition: background-color 0.3s ease;
    box-shadow: 0 4px 15px var(--hover-box-shadow);
}

/* Abgerundete Ecken bei Hover */
.comparison-table tr:hover {
    transform: scale(1.02);
    transition: transform 0.3s ease;
}

/* Responsive Design */
@media (max-width: 768px) {
    .comparison-table th:nth-child(3),
    .comparison-table td:nth-child(3) {
        display: none; /* Verstecke dritte Spalte auf kleinen Bildschirmen */
    }

    .comparison-table th:nth-child(4),
    .comparison-table td:nth-child(4) {
        display: none; /* Verstecke vierte Spalte auf kleinen Bildschirmen */
    }
.icon-check{
    color: green !important;

}
.icon-times{
    color: red !important;
}
    .comparison-table th,
    .comparison-table td {
        font-size: 1rem;
        padding: 10px;
    }
    
    .comparison-table th {
        text-align: center; /* Text zentrieren für mobile Geräte */
    }

    .comparison-table td {
        text-align: left; /* Text in den Zellen linksbündig für bessere Lesbarkeit */
    }
}

/* Noch kleinere Bildschirme */
@media (max-width: 480px) {
    .comparison-table th:nth-child(2),
    .comparison-table td:nth-child(2) {
        display: none; /* Verstecke auch die zweite Spalte auf extrem kleinen Bildschirmen */
    }

    .comparison-table th,
    .comparison-table td {
        font-size: 0.9rem;
        padding: 8px;
    }
}
