:root {
    --trust-bg-light: #f9f9f9; /* Heller Hintergrund im Light Mode */
    --trust-bg-dark: #1e1e1e;  /* Dunkler Hintergrund im Dark Mode */
    --trust-text-light: #333333;
    --trust-text-dark: #f5f5f5;
    --card-bg-light: #ffffff;
    --card-bg-dark: #2b2b2b;
    --card-shadow-light: rgba(0, 0, 0, 0.1);
    --card-shadow-dark: rgba(255, 255, 255, 0.1);
  }
  
  body.light-mode {
    --trust-bg: var(--trust-bg-light);
    --trust-text: var(--trust-text-light);
    --card-bg: var(--card-bg-light);
    --card-shadow: var(--card-shadow-light);
  }
  
  body.dark-mode {
    --trust-bg: var(--trust-bg-dark);
    --trust-text: var(--trust-text-dark);
    --card-bg: var(--card-bg-dark);
    --card-shadow: var(--card-shadow-dark);
  }
  
  .trust-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 20px;
    background-color: var(--trust-bg); /* Hintergrund abhängig vom Modus */
    transition: background-color 0.3s ease;
  }
  
  .trust-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    width: 100%;
    gap: 40px;
  }
  
  .trust-image-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .trust-image {
    max-width: 90%;
    height: auto;
    object-fit: cover;
    filter: brightness(100%); /* Für nahtlose Integration in beide Modi */
    transition: filter 0.3s ease;
  }
  
  body.dark-mode .trust-image {
    filter: brightness(80%); /* Im Dark Mode etwas gedämpfter, damit es besser passt */
  }
  
  .trust-text-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .trust-image {
    max-width: 90%;
    height: auto;
    object-fit: cover;
    filter: brightness(100%); /* Passt sich dem Light Mode an */
    transition: filter 0.3s ease;
}

body.dark-mode .trust-image {
    filter: brightness(80%); /* Im Dark Mode etwas dunkler */
}

  
  .trust-card {
    background-color: var(--card-bg); /* Karten-Hintergrund passend zum Modus */
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 8px 15px var(--card-shadow); /* Leichte Schatten für Tiefe */
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .trust-heading {
    font-size: 2rem;
    color: var(--trust-text); /* Textfarbe abhängig vom Modus */
    margin-bottom: 20px;
  }
  
  .trust-description {
    font-size: 1.2rem;
    color: var(--trust-text);
    line-height: 1.6;
  }
