
.about-us {
  max-width: 1200px;
  width: 100%;
  text-align: center;
  max-width: 1200px;
  width: 100%;
  padding: 40px;
  background-color: var(--card-bg-color);
  border-radius: 10px;
  box-shadow: 0 4px 8px var(--box-shadow);
  text-align: center;
}

.about-us h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: var(--text-color);
}

/* Grid-Layout für die Textkarten mit Double-Card Effekt */
.about-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  grid-auto-rows: minmax(200px, auto);
  
}

.about-card {
  background-color: var(--card-bg-color);
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px var(--box-shadow);
  max-width: 85%;
  margin-left: 7.5%;
}

.about-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px var(--hover-box-shadow);
}

/* Icons Styling */
.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.icon {
  font-size: 3rem;
  color: var(--header-border-color);
}

.about-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: var(--header-color);
}

.about-card p {
  color: var(--paragraph-color);
  font-family: Arial, sans-serif;
}

/* Responsives Design für mobile Geräte */
@media (max-width: 768px) {
  .about-grid {
    grid-template-columns: 1fr;
  }

  .about-card {	
    max-width: 100%;
  margin: 0%;
  }

}
