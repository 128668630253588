/* Hauptcontainer */
.contact-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 40px 20px;
  background: var(--background-color);
}

/* Wrapper für die beiden Boxen */
.contact-wrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch; /* Beide Boxen auf gleiche Höhe bringen */
  gap: 40px;
  max-width: 1200px;
  width: 100%;
}

/* Kontaktformular-Container */
.contact-container {
  background: var(--card-bg-color);
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 5px 20px var(--box-shadow);
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Titel */
.contact-title {
  font-size: 2rem;
  color: var(--text-color);
  font-weight: bold;
  margin-bottom: 20px;
}

/* Kontakt-Info */
.contact-info {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
}

.contact-info-item {
  text-align: center;
  flex: 1;
}

.contact-icon {
  font-size: 1.8rem;
  color: var(--header-border-color);
  margin-bottom: 8px;
}

/* Warum-Box und Map Container */
.contact-text-map-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Warum-Box */
.contact-text-box {
  background: var(--container-bg-color);
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 5px 20px var(--box-shadow);
  text-align: center;
}

/* Überschrift der zweiten Box */
.contact-text-box h2 {
  font-size: 1.8rem;
  color: var(--text-color);
  margin-bottom: 10px;
}

.contact-text-box p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: var(--paragraph-color);
}

/* MapLibre Container */
.contact-map {
  width: 100%;
  height: 300px;
  min-height: 300px;
  background: var(--card-hover-bg-color); /* Falls keine Karte geladen wird */
  border-radius: 12px;
  overflow: hidden;
}

/* Formular */
.contact-form-container {
  margin-top: 20px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-form label {
  font-weight: 500;
  text-align: left;
  display: block;
  color: var(--text-color);
}

/* Eingabefelder */
.form-input {
  width: 100%;
  padding: 12px;
  border: 1px solid var(--header-border-color);
  border-radius: 6px;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
  background: var(--card-bg-color);
  color: var(--text-color);
}

.form-input:focus {
  border-color: var(--cta-button-bg);
  box-shadow: 0 0 8px var(--hover-box-shadow);
  outline: none;
}

/* Checkbox */
.form-check {
  display: flex;
  align-items: center;
  gap: 10px;
}

.form-check label {
  font-size: 0.9rem;
  color: var(--text-color);
}

/* Absende-Button */
.btn-submit {
  background: var(--cta-button-bg);
  color: var(--cta-button-text-color);
  padding: 12px;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn-submit:hover {
  background: var(--cta-button-hover-bg);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px var(--hover-box-shadow);
}

/* Responsive Anpassungen */
@media (max-width: 1024px) {
  .contact-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .contact-container,
  .contact-text-map-container {
    width: 100%;
  }

  .contact-text-box {
    text-align: center;
  }

  .contact-info {
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .contact-page {
    padding: 20px;
  }

  .contact-wrapper {
    flex-direction: column;
    gap: 20px;
  }

  .contact-container {
    padding: 30px;
  }

  .contact-title {
    font-size: 1.6rem;
  }

  .contact-text-box {
    padding: 20px;
  }

  .contact-map {
    height: 250px;
  }

  .form-input {
    font-size: 0.9rem;
  }

  .btn-submit {
    font-size: 1rem;
  }
}
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  font-size: 1rem;
  text-align: center;
}

.alert-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}
