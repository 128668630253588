/* Blog Container */
.blog {
  max-width: 1200px;
  width: 100%;
  padding: 30px;
  background-color: var(--card-bg-color); /* Variable für den Blog-Hintergrund */
  border-radius: 10px;
  box-shadow: 0 4px 8px var(--box-shadow); /* Variable für den Schatten */
  margin-bottom: 20px; /* Abstand nach unten für Bootstrap-Kombination */
}

/* Blog Section Styling */
.blog-section {
  background-color: var(--card-bg-color); /* Variable für den Abschnitt-Hintergrund */
  border-left: 5px solid var(--header-border-color); /* Farbakzent */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px var(--box-shadow); /* Schatten */
  margin-bottom: 20px; /* Abstand zwischen den Abschnitten */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.blog-section:hover {
  background-color: var(--card-hover-bg-color); /* Hover-Hintergrund */
  box-shadow: 0 4px 8px var(--hover-box-shadow); /* Hover-Schatten */
}

/* Überschrift im Blog-Bereich */
.blog h2 {
  margin-bottom: 30px;
  color: var(--text-color); /* Textfarbe */
  font-size: 2rem; /* Kleinere Schriftgröße */
  text-align: center; /* Zentrierte Überschrift */
}

/* Blog Content Styling */
.blog-content {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Abstand zwischen den Abschnitten */
}

/* Überschrift in Blog-Abschnitten */
.blog-section h3 {
  color: var(--text-color); /* Variable für die Textfarbe */
  margin-bottom: 10px;
  font-size: 1.5rem;
}

/* Text im Blog-Abschnitt */
.blog-section p {
  color: var(--paragraph-color); /* Variable für den Text */
  font-family: Arial, sans-serif;
  margin-bottom: 10px;
}

/* Read More Button */
.read-more {
  color: var(--header-border-color); /* Farbakzent */
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
  margin-top: 10px;
}

.read-more:hover {
  text-decoration: underline;
}

/* Responsive Anpassungen für Tablets */
@media (max-width: 768px) {
  .blog {
    padding: 20px; /* Weniger Padding für mobile Geräte */
    box-shadow: none; /* Schatten entfernen für kleine Bildschirme */
  }

  .blog h2 {
    font-size: 2.4rem !important; /* Kleinere Schriftgröße */
  }

  .blog-section {
    padding: 15px; /* Reduziertes Padding für Blog-Abschnitte */
    margin-bottom: 15px;
  }

  .blog-section h3 {
    font-size: 1.3rem;
  }
}

/* Responsive Anpassungen für Smartphones */
@media (max-width: 480px) {
  .blog {
    padding: 15px;
  }

  .blog h2 {
    font-size: 1.5rem;
  }

  .blog-section {
    padding: 10px; /* Weniger Padding für kleinere Bildschirme */
  }

  .blog-section h3 {
    font-size: 1.1rem;
  }

  .read-more {
    font-size: 0.9rem; /* Kleinere Schriftgröße für den Read More Button */
  }
}
