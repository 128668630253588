/* ECommerce.css */

.container {
    padding: 60px 0;
}

.ecommerce-section {
    padding: 40px 20px;
    border-radius: 10px;
    max-width: 1200px;
    margin: 40px auto;
}

.ecommerce-header-section, .ecommerce-cta-section {
    text-align: center;
    background-color: var(--card-bg-color);
    border-radius: 10px;
    box-shadow: 0 4px 12px var(--box-shadow);
    padding: 40px 20px;
    max-width: 1000px;
    margin: 0 auto;
}

.ecommerce-header-section h1, .ecommerce-heading {
    font-size: 2.5em;
    color: var(--header-border-color);
    margin-bottom: 15px;
}

.ecommerce-header-section p, .ecommerce-text {
    font-size: 1.2em;
    color: var(--paragraph-color);
    max-width: 800px;
    margin: 0 auto 20px;
}

.ecommerce-image {
    width: 100%;
    max-width: 800px;
    height: auto;
    border-radius: 10px;
    margin: 20px auto;
    box-shadow: 0 4px 12px var(--box-shadow);
    display: block;
}

/* Vorteile und Dienstleistungen */
.advantages-grid, .services-grid {
    line-break: anywhere;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px; /* Mehr Abstand zwischen den Boxen */
    justify-items: center;
}

.advantage-card, .service-card {
    background-color: var(--card-bg-color);
    border-radius: 10px;
    padding: 40px; /* Mehr Innenabstand für Luftigkeit */
    box-shadow: 0 4px 12px var(--box-shadow);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    max-width: 300px;
    text-align: center;
}

.advantage-card:hover, .service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px var(--hover-box-shadow);
    background-color: var(--card-hover--color);
}

.advantage-icon, .service-icon {
    font-size: 2.8em; /* Größeres Icon für mehr Auffälligkeit */
    color: var(--cta-button-bg);
    margin-bottom: 20px;
}

.advantage-card h5, .service-card h5 {
    font-size: 1.6em; /* Größerer Titeltext */
    color: var(--text-color);
    margin-bottom: 15px;
    font-weight: 700;
}

.advantage-card p, .service-card p {
    font-size: 1.1em;
    color: var(--paragraph-color);
    line-height: 1.8;
}

/* Call-to-Action */
.ecommerce-cta-section h4 {
    font-size: 1.8em;
    color: var(--text-color);
}

.ecommerce-cta-section p {
    font-size: 1.1em;
    color: var(--paragraph-color);
    max-width: 800px;
    margin: 0 auto 20px;
}

/* Mobile Anpassungen */
@media (max-width: 768px) {
    .ecommerce-header-section h1, .ecommerce-heading {
        font-size: 1.8em;
    }

    .advantage-card, .service-card {
        padding: 20px;
        max-width: 100%;
    }

    .advantage-card p, .service-card p {
        font-size: 1em;
    }
}
