@import url(../../assets/variables.css);

:root {
  --side-menu-bg-color: rgba(28, 28, 28, 0.95); /* Hintergrundfarbe für das Seitenmenü */
  --side-menu-text-color: #f5f5f5;
  --navbar-bg-color: rgba(255, 255, 255, 0.9); /* Hintergrundfarbe der Navbar */
  --navbar-text-color: #333333;
  --navbar-link-hover-color: #ff6f61;
  --navbar-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

body.darkmode {
  --navbar-bg-color: rgba(20, 20, 20, 0.95); /* Hintergrundfarbe der Navbar in Darkmode */
  --side-menu-bg-color: rgba(10, 10, 10, 0.95); /* Dunklere Seitenmenüfarbe in Darkmode */
}

/* Standard Navbar */
.navbar {
  background-color: var(--navbar-bg-color); /* Fester Hintergrund für die Navbar */
  box-shadow: var(--navbar-shadow); /* Leichter Schatten unter der Navbar */
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
  display: flex;
  align-items: center; /* Vertikale Ausrichtung für Logo und Links */
  justify-content: space-between; /* Abstand zwischen Logo und Navigation */
  padding: 1rem 2rem;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  color: var(--navbar-text-color);
}



/* Sicherstellen, dass das Logo innerhalb der Navbar skaliert */
.navbar .imgLogo {
  max-height: 100%; /* Maximale Höhe anpassen, um immer in der Navbar sichtbar zu bleiben */
 
  width: auto; /* Verhindert Verzerrung des Logos */
  display: inline-block;
  vertical-align: middle; /* Sicherstellen, dass das Logo zentriert ist */
}

/* Hamburger-Icon bei mobilem Menü */
.navbar-toggler-icon {
  font-size: 1.5rem;
  color: var(--navbar-text-color); /* Dynamische Textfarbe */
}

/* Navigation für große Bildschirme */
.navbar ul {
  list-style: none;
  display: flex;
  gap: 2rem;
  padding: 0;
  margin: 0;
  align-items: center;
}

/* Links in der Navbar */
.nav-link {
  font-size: 1rem;
  color: var(--text-color); /* Dynamische Textfarbe */
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: var(--navbar-link-hover-color); /* Hover-Farbe */
}

/* Hamburger Menü-Toggle */
.navbar-toggler {
  border: none;
  background-color: var(--toggler-bg-color);
  cursor: pointer;
  display: none; /* Verstecken auf größeren Bildschirmen */
}

/* Side Menu */
.side-menu {
  position: fixed;
  top: 0;
  right: -100%; /* Startposition für das ausgeblendete Menü */
  height: 100%;
  width: 300px;
  background-color: var(--side-menu-bg-color);
  color: var(--text-color);
  z-index: 2000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease; /* Animationseffekt für das Menü */
}
.imgLogo {
  height: 7.5vh;

}


.side-menu.open {
  right: 0; /* Sidebar öffnet sich */
}

.side-menu .close-btn {
  align-self: flex-end;
  font-size: 2rem;
  cursor: pointer;
  background: none;
  border: none;
  color: var(--side-menu-text-color);
  margin-bottom: 1rem;
}

.side-menu ul {
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.side-menu ul li {
  width: 100%;
}

.side-menu ul li a {
  color: var(--side-menu-text-color);
  font-size: 1.2rem;
  text-decoration: none;
  transition: color 0.3s ease;
}

.side-menu ul li a:hover {
  color: var(--navbar-link-hover-color);
}

/* Backdrop Overlay */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1500;
}

/* Mobile Optimierung */
@media (max-width: 768px) {
  .navbar {
    padding: 0.5rem 1rem;
    height: 10vh; /* Kleinere Höhe auf mobilen Geräten */
    justify-content: space-between;
  }

  .navbar ul {
    display: none; /* Navigation ausblenden auf mobilen Geräten */
  }

  .navbar-toggler {
    display: block; /* Hamburger Menü für mobile Geräte */
  }

  /* Logo anpassen */
  .navbar .imgLogo {
    height: 7vh; /* Kleinere Höhe des Logos auf mobilen Geräten */
    max-height: 100%; /* Maximale Höhe innerhalb der Navbar */
  }

  /* Side Menu Anpassen */
  .side-menu {
    width: 80%; /* Breite des Side Menus anpassen, um auf mobilen Geräten mehr Platz zu lassen */
    padding: 1.5rem; /* Kleinere Innenabstände */
  }

  .side-menu ul li a {
    font-size: 1rem; /* Kleinere Schriftgröße für mobile Geräte */
  }

  /* Backdrop Anpassung */
  .backdrop {
    background: rgba(0, 0, 0, 0.6); /* Etwas dunklerer Hintergrund, um den Fokus auf das Menü zu lenken */
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 0.5rem;
    height: 12vh; /* Noch kleinere Höhe */
    justify-content: space-between;
  }

  .navbar .imgLogo {
    height: 6vh; /* Noch kleinere Logo-Höhe */
    max-height: 100%; /* Maximalen Raum in der Navbar nutzen */
  }

  .side-menu {
    width: 90%; /* Fast den ganzen Bildschirm auf sehr kleinen Geräten einnehmen */
  }

  .side-menu ul li a {
    font-size: 0.9rem; /* Noch kleinere Schriftgröße */
  }
}
